<template>
  <div class="search-input">
    <input
      ref="searchInput"
      :class="{ error: !!errorMessage }"
      type="text"
      :readonly="readonly"
      :disabled="disabled"
      :placeholder="placeholder"
      :value="defaultValue"
      @input="actions.updateValue($event)"
      @keyup.enter="actions.keyUpEnterEvent($event)"
    />
    <search-icon
      v-if="!disabled"
      class="search-icon"
      width="20"
      height="20"
    ></search-icon>
    <p v-if="!!errorMessage" class="error-msg sub-text-s3 text-red-50">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import SearchIcon from "../icons/SearchIcon";
import { onMounted, reactive, ref } from "vue";
export default {
  name: "InputAddress",
  components: { SearchIcon },
  props: {
    placeholder: {
      type: String,
      default: "도로명, 지번, 건물명으로 검색해주세요",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isKeyupEnterEvent: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const searchInput = ref();
    const state = reactive({});

    onMounted(() => {
      if (props.clickable) {
        searchInput.value.cursor = "pointer";
      }
    });

    const actions = {
      updateValue: (e) => {
        emit("updateValue", e.target.value);
      },
      keyUpEnterEvent: (e) => {
        emit("keyUpEnterEvent", e.target.value);
      },
    };

    return { state, actions, searchInput };
  },
};
</script>

<style scoped>
.search-input {
  position: relative;
  cursor: pointer;
}

.search-input input {
  display: block;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e8e7ed;
  appearance: none;
  border-radius: 0.2rem;
  padding: 14px 16px;
}
.search-input input.error {
  border: 1px solid #ff4646;
}

.search-input input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #d0d5de;
}

.search-input input:disabled {
  background: #ecf1f4;
}

.search-input .search-icon {
  position: absolute;
  top: 15px;
  right: 16px;
}

.search-input .error-msg {
  display: block;
  margin-bottom: 0;
  margin-top: 8px;
}
</style>
