<template>
  <base-modal
    title="정산 정보를 저장할까요?"
    :sub-title="`올바르게 입력했는지 다시 한 번 확인해주세요.<br/>입력해주신 정보를 바탕으로 정산이 처리됩니다.`"
    @closeModal="actions.closeModal()"
  >
    <template #modalBody>
      <div class="info-card">
        <span class="sub-text-s2"> 사업자 유형 : {{ state.corpType }} </span>

        <ul
          v-if="
            parseInt(settlementInfo.type) ===
            settlementConst.corporationType.personal.value
          "
          class="personal-info-list"
        >
          <li>
            <span class="sub-text-s2">이름 : {{ settlementInfo.name }}</span>
          </li>
          <li>
            <span class="sub-text-s2">
              주민등록번호 : {{ state.idNumber }}
            </span>
          </li>
          <li>
            <span class="sub-text-s2">
              휴대폰번호 : {{ state.phoneNumber }}
            </span>
          </li>
        </ul>

        <ul
          v-if="
            parseInt(settlementInfo.type) !==
            settlementConst.corporationType.personal.value
          "
          class="personal-info-list"
        >
          <li>
            <span class="sub-text-s2">
              상호명 : {{ settlementInfo.businessName }}
            </span>
          </li>
          <li>
            <span class="sub-text-s2">
              대표자명 : {{ settlementInfo.name }}
            </span>
          </li>
          <li>
            <span class="sub-text-s2">
              사업자 등록번호 : {{ state.idNumber }}
            </span>
          </li>
          <li>
            <span class="sub-text-s2">
              업태명 : {{ settlementInfo.businessCategory }}
            </span>
          </li>
          <li>
            <span class="sub-text-s2">
              종목명 : {{ settlementInfo.businessCondition }}
            </span>
          </li>
          <li>
            <span class="sub-text-s2">
              사업장 주소 : {{ settlementInfo.address }}
            </span>
          </li>
          <li>
            <span class="sub-text-s2">
              이메일 주소 : {{ settlementInfo.managerEmail }}
            </span>
          </li>
        </ul>

        <ul class="account-info-list">
          <li>
            <span class="sub-text-s2">
              입금 은행 : {{ settlementInfo.bank }}
            </span>
          </li>
          <li>
            <span class="sub-text-s2">
              예금주명 : {{ settlementInfo.bankAccountHolder }}
            </span>
          </li>
          <li>
            <span class="sub-text-s2">
              계좌번호 : {{ settlementInfo.bankAccount }}
            </span>
          </li>
        </ul>
      </div>
      <div class="btn-wrapper">
        <button class="cancel-btn sub-title-s1" @click="actions.closeModal()">
          취소
        </button>
        <button
          class="save-btn sub-title-s1 text-gray-reverse"
          @click="actions.save()"
        >
          저장
        </button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../../../../components/common/BaseModal";
import { computed, reactive } from "vue";
import settlementConst from "@/consts/settlement";
import helper from "@/helper";

export default {
  name: "SaveInfoModal",
  components: { BaseModal },
  props: {
    settlementInfo: {
      type: Object,
      required: true,
    },
  },
  emits: ["confirm", "hideModal"],
  setup(props, { emit }) {
    const state = reactive({
      corpType: computed(() => {
        switch (parseInt(props.settlementInfo.type)) {
          case settlementConst.corporationType.personal.value:
            return settlementConst.corporationType.personal.text;
          case settlementConst.corporationType.personalCorp.value:
            return settlementConst.corporationType.personalCorp.text;
          default:
            return settlementConst.corporationType.corp.text;
        }
      }),
      isPersonalType: computed(() => {
        return (
          parseInt(props.settlementInfo.type) ===
          settlementConst.corporationType.personal.value
        );
      }),
      idNumber: computed(() => {
        if (state.isPersonalType) {
          return helper.idNumberFormat(props.settlementInfo.idNumber);
        } else {
          return helper.corpIdNumberFormat(props.settlementInfo.idNumber);
        }
      }),
      phoneNumber: computed(() => {
        return props.settlementInfo.managerPhone
          ? helper.phoneNumberFormat(props.settlementInfo.managerPhone)
          : "";
      }),
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      save: () => {
        emit("confirm");
      },
    };

    return { state, actions, settlementConst };
  },
};
</script>

<style src="./style.css" scoped></style>
