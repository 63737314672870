<template>
  <div>
    <page-loading v-if="state.pageLoading"></page-loading>

    <div v-if="!state.pageLoading" class="tab-info-wrapper">
      <div
        v-if="!state.existSettlementInfo && !state.isEditMode"
        class="type-card"
      >
        <p>사업자 유형을 선택해주세요.</p>
        <div class="types">
          <div
            class="type-btn"
            :class="{
              active:
                state.corporationType ===
                settlementConst.corporationType.personal.value,
            }"
            @click="
              actions.setCorporationType(
                settlementConst.corporationType.personal.value
              )
            "
          >
            <img src="/assets/images/settlement/smile.png" />
            <p class="sub-text-s3 text-gray-second">개인</p>
          </div>
          <div
            class="type-btn"
            :class="{
              active:
                state.corporationType ===
                settlementConst.corporationType.personalCorp.value,
            }"
            @click="
              actions.setCorporationType(
                settlementConst.corporationType.personalCorp.value
              )
            "
          >
            <img src="/assets/images/settlement/briefcase.png" />
            <p class="sub-text-s3 text-gray-second">개인 사업자</p>
          </div>
          <div
            class="type-btn"
            :class="{
              active:
                state.corporationType ===
                settlementConst.corporationType.corp.value,
            }"
            @click="
              actions.setCorporationType(
                settlementConst.corporationType.corp.value
              )
            "
          >
            <img src="/assets/images/settlement/building.png" />
            <p class="sub-text-s3 text-gray-second">법인 사업자</p>
          </div>
        </div>

        <button-basic
          class="type-save__btn"
          text="다음"
          :disabled="state.corporationType === 0"
          @click="toggleEditMode()"
        >
        </button-basic>
      </div>

      <div
        v-if="state.existSettlementInfo && !state.isEditMode"
        class="exist-info"
      >
        <card-basic class="status-card">
          <h4>
            정산 정보 상태
            <help-tooltip
              icon-color="#0D0D10"
              :tooltip-text="`정산 정보에 문제가 없는지 확인하는 과정이에요.<br />심사가 완료된 정보를 바탕으로 정산이 진행돼요.`"
            ></help-tooltip>
          </h4>
          <p
            class="b-text-1"
            :class="{
              'text-blue-50':
                state.status === settlementConst.infoStatus.confirm.value,
              'text-red-50':
                state.status !== settlementConst.infoStatus.confirm.value,
            }"
          >
            {{ state.statusText }}
          </p>
        </card-basic>

        <card-basic class="prev-card">
          <div class="title">
            <h4>기본정보</h4>
            <button
              class="sub-title-s1 text-purple-50"
              @click="toggleEditMode()"
            >
              수정
            </button>
          </div>

          <ul v-if="state.isPersonalType">
            <li>
              <span class="head sub-text-s2 text-gray-second">사업자 유형</span>
              <span class="b-text-1">{{ state.corporationTypeText }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">이름</span>
              <span>{{ state.settlementInfo.name }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">
                주민등록번호
              </span>
              <span>{{ state.savedIdNumber }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">휴대폰번호</span>
              <span>{{ state.savedPhoneNumber }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">
                주민등록증 사본
              </span>
              <button-basic
                bg-color="#ffffff"
                color="#0D0D10"
                text-size="s3"
                :border="true"
                border-color="#E6EAEF"
                padding="16px"
                icon-position="front"
                text="주민등록증사본"
              >
                <template #icon>
                  <file-icon></file-icon>
                </template>
              </button-basic>
            </li>
          </ul>

          <ul v-if="!state.isPersonalType">
            <li>
              <span class="head sub-text-s2 text-gray-second">사업자 유형</span>
              <span class="b-text-1">{{ state.corporationTypeText }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">상호명</span>
              <span>{{ state.settlementInfo.businessName }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">대표자명</span>
              <span>{{ state.settlementInfo.name }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">
                사업자 등록번호
              </span>
              <span>{{ state.settlementInfo.idNumber }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second"> 업태명 </span>
              <span>{{ state.settlementInfo.businessCategory }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second"> 종목명 </span>
              <span>{{ state.settlementInfo.businessCondition }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">
                사업장 주소
              </span>
              <span>{{ state.settlementInfo.businessAddress }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">
                사업자등록증 사본
              </span>
              <button-basic
                bg-color="#ffffff"
                color="#0D0D10"
                text-size="s3"
                :border="true"
                border-color="#E6EAEF"
                padding="16px"
                icon-position="front"
                text="사업자등록증"
              >
                <template #icon>
                  <file-icon></file-icon>
                </template>
              </button-basic>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">
                세금계산서 수신 이메일 주소
              </span>
              <span>{{ state.settlementInfo.managerEmail }}</span>
            </li>
          </ul>

          <hr class="hr-third" style="margin: 32px 0" />

          <h4 class="title">입금계좌 정보</h4>
          <ul>
            <li>
              <span class="head sub-text-s2 text-gray-second">은행</span>
              <span>{{ state.settlementInfo.bank }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">예금주명</span>
              <span>{{ state.settlementInfo.bankAccountHolder }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">계좌번호</span>
              <span>{{ state.settlementInfo.bankAccount }}</span>
            </li>
            <li>
              <span class="head sub-text-s2 text-gray-second">통장 사본</span>
              <button-basic
                bg-color="#ffffff"
                color="#0D0D10"
                text-size="s3"
                :border="true"
                border-color="#E6EAEF"
                padding="16px"
                icon-position="front"
                text="통장사본"
              >
                <template #icon>
                  <file-icon></file-icon>
                </template>
              </button-basic>
            </li>
          </ul>
        </card-basic>
      </div>

      <div v-if="state.isEditMode" class="form-list">
        <card-basic class="corporation-type">
          <h4>사업자 유형<span>*</span></h4>
          <ul>
            <li>
              <input-radio
                v-model="state.corporationType"
                name="corporation"
                for-value="corporation1"
                label="개인"
                :value="settlementConst.corporationType.personal.value"
              ></input-radio>
            </li>
            <li>
              <input-radio
                v-model="state.corporationType"
                name="corporation"
                for-value="corporation2"
                label="개인 사업자"
                :value="settlementConst.corporationType.personalCorp.value"
              ></input-radio>
            </li>
            <li>
              <input-radio
                v-model="state.corporationType"
                name="corporation"
                for-value="corporation3"
                label="법인 사업자"
                :value="settlementConst.corporationType.corp.value"
              ></input-radio>
            </li>
          </ul>
        </card-basic>

        <!--	개인 폼  -->
        <card-basic v-if="state.showPersonalForm" class="personal-form">
          <h4>개인정보 입력<span>*</span></h4>
          <input-basic-new
            class="input-form"
            label="이름"
            placeholder="이름을 입력해주세요"
            :default-value="state.personName"
            :error-message="state.personNameError"
            @updateData="(value) => actions.updatePersonName(value)"
          ></input-basic-new>

          <input-format
            class="input-form"
            label="주민등록번호"
            placeholder="주민등록번호를 입력해주세요"
            :value="state.idNumber"
            :error-message="state.idNumberError"
            :format-function="helper.idNumberFormat"
            @updateData="(value) => actions.updateIdNumber(value)"
          ></input-format>

          <input-format
            class="input-form"
            label="휴대폰번호"
            placeholder="휴대폰번호를 입력해주세요"
            :value="state.phoneNumber"
            :error-message="state.phoneNumberError"
            :format-function="helper.phoneNumberFormat"
            @updateData="(value) => actions.updatePhoneNumber(value)"
          ></input-format>

          <div class="image-form">
            <p class="label-title sub-text-s2">주민등록증 사본</p>
            <div
              class="file-upload-card"
              :class="{ error: state.idCardCopyFileError }"
              @click="actions.openFileStackForIdCardFile()"
            >
              <div class="text">
                <div class="upload-icon-wrapper">
                  <upload-icon></upload-icon>
                </div>

                <h4 class="title">파일 업로드</h4>
                <p class="c-text text-second">
                  *업로드 파일 형식 : jpg, png, pdf
                </p>
              </div>
            </div>

            <div v-if="state.idCardCopyFile" class="uploaded-file">
              <span><file-icon fill-color="#0D0D10"></file-icon></span>
              <span class="sub-text-s3">
                {{ state.idCardCopyFile.filename }}
              </span>
              <text-divider
                color="#E6EAEF"
                margin="0 12px"
                height="16px"
              ></text-divider>
              <span class="sub-text-s3 text-gray-second">
                {{ state.idCardCopyFile.size }}
              </span>
            </div>
          </div>
        </card-basic>

        <!--	사업자 폼  -->
        <card-basic v-if="!state.showPersonalForm" class="corporation-form">
          <h4>사업자 정보 입력<span>*</span></h4>

          <input-basic-new
            class="input-form"
            label="상호명"
            placeholder="상호명을 입력해주세요"
            :default-value="state.businessName"
            :error-message="state.businessNameError"
            @updateData="(value) => actions.updateBusinessName(value)"
          ></input-basic-new>

          <input-basic-new
            class="input-form"
            label="대표자명"
            placeholder="대표자명을 입력해주세요"
            :default-value="state.corpPersonName"
            :error-message="state.corpPersonNameError"
            @updateData="(value) => actions.updateCorpPersonName(value)"
          ></input-basic-new>

          <input-format
            class="input-form"
            label="사업자 등록번호"
            placeholder="사업자 등록번호를 입력해주세요"
            :value="state.corpIdNumber"
            :format-function="helper.corpIdNumberFormat"
            :error-message="state.corpIdNumberError"
            @updateData="(value) => actions.updateCorpIdNumber(value)"
          ></input-format>

          <div class="form-wrapper">
            <input-basic-new
              class="input-form"
              label="업태명"
              placeholder="업태명을 입력해주세요"
              :default-value="state.businessCategory"
              :error-message="state.businessCategoryError"
              @updateData="(value) => actions.updateBusinessCategory(value)"
            ></input-basic-new>

            <input-basic-new
              class="input-form"
              label="종목명"
              placeholder="종목명을 입력해주세요"
              :default-value="state.businessCondition"
              :error-message="state.businessConditionError"
              @updateData="(value) => actions.updateBusinessCondition(value)"
            ></input-basic-new>
          </div>

          <div class="address-form">
            <p class="label sub-text-s2">사업장 주소</p>
            <input-address
              placeholder="도로명, 지번, 건물명으로 검색해주세요"
              :clickable="true"
              :readonly="true"
              :default-value="state.address"
              :error-message="state.addressError"
              @click="toggleAddressModal()"
            ></input-address>
          </div>

          <div class="image-form">
            <p class="label-title sub-text-s2">사업자 등록증 사본</p>
            <div
              class="file-upload-card"
              :class="{ error: state.corpIdCardCopyFileError }"
              @click="actions.openFileStackForCorpIdCardFile()"
            >
              <div class="text">
                <div class="upload-icon-wrapper">
                  <upload-icon></upload-icon>
                </div>

                <h4 class="title">파일 업로드</h4>
                <p class="c-text text-second">
                  *업로드 파일 형식 : jpg, png, pdf
                </p>
              </div>
            </div>
            <div v-if="state.corpIdCardCopyFile" class="uploaded-file">
              <span><file-icon fill-color="#0D0D10"></file-icon></span>
              <span class="sub-text-s3">
                {{ state.corpIdCardCopyFile.filename }}
              </span>
              <text-divider
                color="#E6EAEF"
                margin="0 12px"
                height="16px"
              ></text-divider>
              <span class="sub-text-s3 text-gray-second">
                {{ state.corpIdCardCopyFile.size }}
              </span>
            </div>
          </div>

          <input-basic-new
            label="세금계산서 수신 이메일 주소"
            placeholder="세금계산서를 수신할 이메일 주소를 입력해주세요"
            :default-value="state.email"
            :error-message="state.emailError"
            @updateData="(value) => actions.setManagerEmail(value)"
          ></input-basic-new>
        </card-basic>

        <card-basic class="account-form">
          <h4>입금계좌 정보 입력<span>*</span></h4>
          <div class="bank-info">
            <input-select
              class="select-form"
              label="은행"
              :list="settlementConst.bankList"
              :selected-item="state.selectedBank"
              :is-error="state.selectedBankError"
              @select="(item) => actions.selectBank(item)"
            ></input-select>

            <input-basic-new
              class="input-form"
              label="예금주명"
              placeholder="계좌의 예금주명을 입력해주세요"
              :default-value="state.bankAccountHolder"
              :error-message="state.bankAccountHolderError"
              @updateData="(value) => actions.updateBankAccountHolder(value)"
            ></input-basic-new>
          </div>

          <input-basic-new
            class="input-form"
            label="계좌번호"
            placeholder="계좌번호를 입력해주세요"
            :default-value="state.bankAccount"
            :error-message="state.bankAccountError"
            @updateData="(value) => actions.updateBankAccount(value)"
          ></input-basic-new>

          <div class="image-form">
            <p class="label-title sub-text-s2">통장 사본</p>
            <div
              class="file-upload-card"
              :class="{ error: state.bankbookCopyFileError }"
              @click="actions.openFileStackForBankbookFile()"
            >
              <div class="text">
                <div class="upload-icon-wrapper">
                  <upload-icon></upload-icon>
                </div>

                <h4 class="title">파일 업로드</h4>
                <p class="c-text text-second">
                  *업로드 파일 형식 : jpg, png, pdf
                </p>
              </div>
            </div>

            <div v-if="state.bankbookCopyFile" class="uploaded-file">
              <span><file-icon fill-color="#0D0D10"></file-icon></span>
              <span class="sub-text-s3">
                {{ state.bankbookCopyFile.filename }}
              </span>
              <text-divider
                color="#E6EAEF"
                margin="0 12px"
                height="16px"
              ></text-divider>
              <span class="sub-text-s3 text-gray-second">
                {{ state.bankbookCopyFile.size }}
              </span>
            </div>
          </div>
        </card-basic>

        <div class="btn-wrapper">
          <button-basic
            v-if="state.existSettlementInfo"
            class="cancel-btn"
            text="취소"
            bg-color="#ecf1f4"
            :color="state.isSaving ? '#d0d5de' : '#0D0D10'"
            @click="toggleEditMode()"
          ></button-basic>

          <tooltip class="save-tooltip" :triggers="['click']">
            <template #button>
              <button-basic
                :text="state.isSaving ? '저장중...' : '저장하기'"
                :bg-color="!state.activeSaveBtn ? '#ecf1f4' : '#8E1EFF'"
                :color="!state.activeSaveBtn ? '#d0d5de' : '#FFFFFF'"
                @click="actions.saveCalculateInfo()"
              >
              </button-basic>
            </template>
            <template #tooltip>
              <p class="tooltip-content c-text text-gray-second">
                모든 정보를 입력하셔야 합니다.
              </p>
            </template>
          </tooltip>
        </div>
      </div>
    </div>

    <teleport :disabled="true">
      <address-modal
        v-if="state.showAddressModal"
        @hideModal="toggleAddressModal()"
        @setAddress="(address) => actions.setAddress(address)"
      ></address-modal>

      <save-info-modal
        v-if="state.showSaveModal"
        :settlement-info="state.settlementInfoForm"
        @hideModal="toggleSaveModal()"
        @confirm="actions.saveInfo()"
      ></save-info-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive } from "vue";
import CardBasic from "@/components/console/cards/CardBasic";
import InputRadio from "@/components/console/inputs/InputRadio";
import UploadIcon from "@/components/console/icons/UploadIcon";
import InputSelect from "@/components/console/inputs/InputSelect";
import InputAddress from "@/components/console/inputs/InputAddress";
import AddressModal from "../AddressModal/AddressModal";
import SaveInfoModal from "../SaveInfoModal/SaveInfoModal";
import InputFormat from "@/components/console/inputs/InputFormat";
import helper from "@/helper";
import FileStackService from "../../../../services/FileStackService";
import TextDivider from "@/components/console/dividers/TextDivider";
import FileIcon from "@/components/console/icons/FileIcon";
import settlementConst from "@/consts/settlement";
import { useStore } from "vuex";
import swal from "@/helper/swal";
import PageLoading from "@/components/console/loadings/PageLoading";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import { debounce } from "lodash";
import InputBasicNew from "@/components/console/inputs/InputBasicNew.vue";
import Tooltip from "@/components/console/tooltip/Tooltip.vue";
import HelpTooltip from "@/components/console/tooltip/HelpTooltip.vue";

export default {
  name: "TabInfo",
  components: {
    HelpTooltip,
    Tooltip,
    InputBasicNew,
    ButtonBasic,
    PageLoading,
    FileIcon,
    TextDivider,
    InputFormat,
    SaveInfoModal,
    AddressModal,
    InputAddress,
    InputSelect,
    UploadIcon,
    InputRadio,
    CardBasic,
  },
  setup() {
    const store = useStore();

    const essentialErrorMessage = "필수 항목을 입력해주세요";
    const validateErrorMessage = "올바르게 입력했는지 확인해주세요.";

    const state = reactive({
      pageLoading: true,
      showPersonalForm: computed(() => {
        return (
          parseInt(state.corporationType) ===
          settlementConst.corporationType.personal.value
        );
      }),
      corporationType: 0,
      corporationTypeText: computed(() => {
        switch (state.settlementInfo.type) {
          case settlementConst.corporationType.personal.value:
            return settlementConst.corporationType.personal.text;
          case settlementConst.corporationType.personalCorp.value:
            return settlementConst.corporationType.personalCorp.text;
          default:
            return settlementConst.corporationType.corp.text;
        }
      }),
      personName: "",
      personNameError: "",
      corpPersonName: "",
      corpPersonNameError: "",
      businessName: "",
      businessNameError: "",
      businessCategory: "",
      businessCategoryError: "",
      businessCondition: "",
      businessConditionError: "",
      idNumber: "",
      idNumberError: "",
      savedIdNumber: computed(() => {
        return `${state.settlementInfo.idNumber.slice(0, 6)} - ●●●●●●●`;
      }),
      idCardCopyFile: null,
      idCardCopyFileError: false,
      corpIdNumber: "",
      corpIdNumberError: "",
      corpIdCardCopyFile: null,
      corpIdCardCopyFileError: false,
      corpIdCardCopyUrl: "",
      selectedBank: {
        value: null,
        text: "은행을 선택해주세요.",
      },
      selectedBankError: false,
      bankAccount: "",
      bankAccountError: "",
      bankAccountHolder: "",
      bankAccountHolderError: "",
      bankbookCopyFile: null,
      bankbookCopyFileError: false,
      address: "",
      addressError: "",
      showAddressModal: false,
      showSaveModal: false,
      phoneNumber: "",
      phoneNumberError: "",
      savedPhoneNumber: computed(() => {
        return state.settlementInfo.managerPhone
          ? helper.phoneNumberFormat(state.settlementInfo.managerPhone)
          : "";
      }),
      email: "",
      emailError: "",
      settlementInfoForm: computed(() => {
        let result = {
          type: state.corporationType,
          bank: state.selectedBank.value ? state.selectedBank.text : null,
          bankAccount: state.bankAccount,
          bankAccountHolder: state.bankAccountHolder,
          managerPhone: state.phoneNumber,
        };

        if (state.bankbookCopyFile) {
          result.bankbookCopyFile = state.bankbookCopyFile.url;
        }

        if (
          parseInt(state.corporationType) ===
          settlementConst.corporationType.personal.value
        ) {
          result.name = state.personName;
          result.idNumber = state.idNumber;
          if (state.idCardCopyFile) {
            result.idFile = state.idCardCopyFile.url;
          }
        } else {
          result.name = state.corpPersonName;
          result.businessName = state.businessName;
          result.businessCategory = state.businessCategory;
          result.businessCondition = state.businessCondition;
          result.idNumber = state.corpIdNumber;
          result.address = state.address;
          result.managerEmail = state.email;
          if (state.corpIdCardCopyFile) {
            result.idFile = state.corpIdCardCopyFile.url;
          }
        }

        return result;
      }),
      settlementInfo: computed(() => {
        return store.getters["settlements/settlementInfo"];
      }),
      existSettlementInfo: computed(() => {
        return store.getters["settlements/existSettlementInfo"];
      }),
      status: computed(() => {
        return state.settlementInfo.status;
      }),
      statusText: computed(() => {
        switch (state.status) {
          case settlementConst.infoStatus.wait.value:
            return settlementConst.infoStatus.wait.text;
          case settlementConst.infoStatus.confirm.value:
            return settlementConst.infoStatus.confirm.text;
          default:
            return `${settlementConst.infoStatus.fail.text} - ${state.settlementInfo.failReason}`;
        }
      }),
      activeSaveBtn: computed(() => {
        const completeBankInfo =
          !!state.selectedBank.value &&
          !!state.bankAccountHolder &&
          !!state.bankAccount &&
          !!state.bankbookCopyFile;

        return parseInt(state.corporationType) ===
          settlementConst.corporationType.personal.value
          ? !!(
              !state.isSaving &&
              !!state.personName &&
              helper.validateIdNumber(state.idNumber) &&
              helper.validatePhoneNumber(state.phoneNumber) &&
              !!state.idCardCopyFile &&
              completeBankInfo
            )
          : !!(
              !state.isSaving &&
              !!state.businessName &&
              !!state.corpPersonName &&
              !!state.businessCategory &&
              helper.validateCorpIdNumberFormat(state.corpIdNumber) &&
              helper.validateEmail(state.email) &&
              !!state.businessCondition &&
              !!state.address &&
              !!state.corpIdCardCopyFile &&
              completeBankInfo
            );
      }),
      isPersonalType: computed(() => {
        return (
          state.settlementInfo.type ===
          settlementConst.corporationType.personal.value
        );
      }),
      isSaving: false,
      isEditMode: false,
    });

    onBeforeMount(() => {
      store.dispatch("settlements/getSettlementInfo").then(() => {
        state.pageLoading = false;
      });
    });

    const saveSettlementInfo = async () => {
      let payload = {
        type: state.corporationType,
        bank: state.selectedBank.text,
        bankAccount: state.bankAccount,
        bankAccountHolder: state.bankAccountHolder,
        bankbookCopyFile: state.bankbookCopyFile.url,
        managerPhone: state.phoneNumber,
      };
      if (
        parseInt(state.corporationType) ===
        settlementConst.corporationType.personal.value
      ) {
        payload.name = state.personName;
        payload.idNumber = state.idNumber;
        payload.managerPhone = state.phoneNumber;
        payload.idFile = state.idCardCopyFile.url;
      } else {
        payload.name = state.corpPersonName;
        payload.businessName = state.businessName;
        payload.businessCategory = state.businessCategory;
        payload.businessCondition = state.businessCondition;
        payload.idNumber = state.corpIdNumber;
        payload.businessAddress = state.address;
        payload.managerEmail = state.email;
        payload.idFile = state.corpIdCardCopyFile.url;
      }

      await store
        .dispatch("settlements/setSettlementInfo", payload)
        .then(() => {
          swal.messageAlert("정산 정보가 저장되었습니다.");
          state.isSaving = false;
          toggleEditMode();
        });
    };

    const setSettlementInfoForm = (settlementInfo) => {
      if (
        settlementInfo.type === settlementConst.corporationType.personal.value
      ) {
        state.personName = settlementInfo.name;
        state.idNumber = settlementInfo.idNumber;
        state.phoneNumber = settlementInfo.managerPhone;
        state.idCardCopyFile = {
          filename: "주민등록증 사본",
          url: settlementInfo.idFile,
        };
      } else {
        state.corpPersonName = settlementInfo.name;
        state.businessCategory = settlementInfo.businessCategory;
        state.businessCondition = settlementInfo.businessCondition;
        state.businessName = settlementInfo.businessName;
        state.corpIdNumber = settlementInfo.idNumber;
        state.corpIdCardCopyFile = {
          filename: "사업자등록증 사본",
          url: settlementInfo.idFile,
        };
        state.email = settlementInfo.managerEmail
          ? settlementInfo.managerEmail
          : "";
        state.address = settlementInfo.businessAddress;
      }

      state.corporationType = state.existSettlementInfo
        ? settlementInfo.type
        : state.corporationType;

      if (state.existSettlementInfo) {
        state.selectedBank = settlementConst.bankList.find((item) => {
          return item.text === settlementInfo.bank;
        });
        state.bankAccount = settlementInfo.bankAccount;
        state.bankAccountHolder = settlementInfo.bankAccountHolder;
        state.bankbookCopyFile = {
          filename: "통장 사본",
          url: settlementInfo.bankbookCopyFile,
        };
      }
    };

    const checkIdNumberValidate = debounce(() => {
      let isValid = helper.validateIdNumber(state.idNumber);
      if (!isValid && !!state.idNumber) {
        state.idNumberError = validateErrorMessage;
      }
    }, 500);

    const checkPhoneNumberValidate = debounce(() => {
      let isValid = helper.validatePhoneNumber(state.phoneNumber);
      if (!isValid && !!state.phoneNumber) {
        state.phoneNumberError = validateErrorMessage;
      }
    }, 500);

    const checkCorpNumberValidate = debounce(() => {
      let isValid = helper.validateCorpIdNumberFormat(state.corpIdNumber);
      if (!isValid && !!state.corpIdNumber) {
        state.corpIdNumberError = validateErrorMessage;
      }
    }, 500);

    const checkEmailValidate = debounce(() => {
      let isValid = helper.validateEmail(state.email);

      if (!isValid && !!state.email) {
        state.emailError = validateErrorMessage;
      }
    }, 500);

    const checkEssentialValues = () => {
      if (
        parseInt(state.corporationType) ===
        settlementConst.corporationType.personal.value
      ) {
        if (!state.personName) {
          state.personNameError = essentialErrorMessage;
        }
        if (!state.idNumber) {
          state.idNumberError = essentialErrorMessage;
        }
        if (!state.phoneNumber) {
          state.phoneNumberError = essentialErrorMessage;
        }
        if (!state.idCardCopyFile) {
          state.idCardCopyFileError = true;
        }
      } else {
        if (!state.businessName) {
          state.businessNameError = essentialErrorMessage;
        }
        if (!state.corpPersonName) {
          state.corpPersonNameError = essentialErrorMessage;
        }
        if (!state.corpIdNumber) {
          state.corpIdNumberError = essentialErrorMessage;
        }
        if (!state.businessCategory) {
          state.businessCategoryError = essentialErrorMessage;
        }
        if (!state.businessCondition) {
          state.businessConditionError = essentialErrorMessage;
        }
        if (!state.address) {
          state.addressError = essentialErrorMessage;
        }
        if (!state.corpIdCardCopyFile) {
          state.corpIdCardCopyFileError = true;
        }
        if (!state.email) {
          state.emailError = essentialErrorMessage;
        }
      }

      if (!state.selectedBank.value) {
        state.selectedBankError = true;
      }
      if (!state.bankAccountHolder) {
        state.bankAccountHolderError = essentialErrorMessage;
      }
      if (!state.bankAccount) {
        state.bankAccountError = essentialErrorMessage;
      }
      if (!state.bankbookCopyFile) {
        state.bankbookCopyFileError = true;
      }
    };

    const toggleAddressModal = () => {
      state.showAddressModal = !state.showAddressModal;
    };
    const toggleSaveModal = () => {
      state.showSaveModal = !state.showSaveModal;
    };

    const toggleEditMode = () => {
      if (!state.isEditMode) {
        setSettlementInfoForm(state.settlementInfo);
      }
      state.isEditMode = !state.isEditMode;
    };

    const actions = {
      selectBank: (bank) => {
        if (state.selectedBankError) {
          state.selectedBankError = false;
        }
        state.selectedBank = bank;
      },
      setAddress: (address) => {
        if (state.addressError) {
          state.addressError = "";
        }
        state.address = address;
        toggleAddressModal();
      },
      updatePhoneNumber: (value) => {
        if (state.phoneNumberError) {
          state.phoneNumberError = "";
        }
        state.phoneNumber = value;
        checkPhoneNumberValidate();
      },
      updateCorpIdNumber: (value) => {
        if (state.corpIdNumberError) {
          state.corpIdNumberError = "";
        }
        state.corpIdNumber = value;
        checkCorpNumberValidate();
      },
      setManagerEmail: (value) => {
        if (state.emailError) {
          state.emailError = "";
        }
        state.email = value;
        checkEmailValidate();
      },
      updateBusinessName: (value) => {
        if (state.businessNameError) {
          state.businessNameError = "";
        }
        state.businessName = value;
      },
      updatePersonName: (value) => {
        state.personName = value;
        if (state.personNameError) {
          state.personNameError = "";
        }
      },
      updateCorpPersonName: (value) => {
        if (state.corpPersonNameError) {
          state.corpPersonNameError = "";
        }
        state.corpPersonName = value;
      },
      updateBusinessCategory: (value) => {
        if (state.businessCategoryError) {
          state.businessCategoryError = "";
        }
        state.businessCategory = value;
      },
      updateBusinessCondition: (value) => {
        if (state.businessConditionError) {
          state.businessConditionError = "";
        }
        state.businessCondition = value;
      },
      updateIdNumber: (value) => {
        state.idNumber = value;
        if (state.idNumberError) {
          state.idNumberError = "";
        }
        checkIdNumberValidate();
      },
      updateBankAccount: (value) => {
        if (state.bankAccountError) {
          state.bankAccountError = "";
        }
        state.bankAccount = value;
      },
      updateBankAccountHolder: (value) => {
        if (state.bankAccountHolderError) {
          state.bankAccountHolderError = "";
        }
        state.bankAccountHolder = value;
      },
      setCorporationType: (value) => {
        state.corporationType = value;
      },
      openFileStackForIdCardFile: () => {
        const fileStack = new FileStackService();
        fileStack.options.accept = [
          "image/png",
          "image/jpeg",
          "application/pdf",
        ];
        fileStack.options.transformations.crop.aspectRatio = 43 / 27;
        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          if (state.idCardCopyFileError) {
            state.idCardCopyFileError = false;
          }
          state.idCardCopyFile = fileMetaData;
        };

        fileStack.open(fileStack.options);
      },
      openFileStackForCorpIdCardFile: () => {
        const fileStack = new FileStackService();
        fileStack.options.accept = [
          "image/png",
          "image/jpeg",
          "application/pdf",
        ];
        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          if (state.corpIdCardCopyFileError) {
            state.corpIdCardCopyFileError = false;
          }
          state.corpIdCardCopyFile = fileMetaData;
        };

        fileStack.open(fileStack.options);
      },
      openFileStackForBankbookFile: () => {
        const fileStack = new FileStackService();
        fileStack.options.accept = [
          "image/png",
          "image/jpeg",
          "application/pdf",
        ];
        fileStack.options.transformations.crop = true;
        fileStack.options.transformations.circle = false;
        fileStack.options.transformations.rotate = false;

        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          if (state.bankbookCopyFileError) {
            state.bankbookCopyFileError = false;
          }
          state.bankbookCopyFile = fileMetaData;
          console.log(state.bankbookCopyFile);
        };

        fileStack.open(fileStack.options);
      },
      saveCalculateInfo: () => {
        checkEssentialValues();
        if (!state.activeSaveBtn) {
          return;
        }
        toggleSaveModal();
      },
      saveInfo: async () => {
        state.isSaving = true;
        toggleSaveModal();
        await saveSettlementInfo();
      },
    };

    return {
      state,
      actions,
      helper,
      settlementConst,
      toggleAddressModal,
      toggleSaveModal,
      toggleEditMode,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
