<template>
  <base-modal title="사업장 주소 등록" @closeModal="actions.closeModal()">
    <template #modalBody>
      <div v-if="!state.showDaumAddressForm">
        <input-address
          class="address-input"
          placeholder="도로명, 지번으로 검색해주세요"
          :readonly="true"
          :disabled="!!state.resAddress"
          :default-value="state.resAddress"
          @click="!state.resAddress ? actions.openDaumAddressForm() : ''"
        ></input-address>
        <!--        <input-basic-new-->
        <!--          class="input-form"-->
        <!--          placeholder=""-->
        <!--          :disabled="true"-->
        <!--          :default-value="state.resAddress"-->
        <!--        ></input-basic-new>-->

        <input-basic-new
          v-if="!!state.resAddress"
          class="input-form"
          placeholder="세부 주소를 입력해주세요"
          :default-value="state.detailAddress"
          @updateData="(value) => actions.updateDetailAddress(value)"
        ></input-basic-new>

        <div v-if="!!state.resAddress" class="search-btn">
          <button class="sub-title-s2" @click="actions.openDaumAddressForm()">
            다른 주소 검색
            <search-icon stroke-size="normal"></search-icon>
          </button>
        </div>

        <div class="btn-wrapper">
          <button class="cancel-btn sub-title-s1" @click="actions.closeModal()">
            취소
          </button>
          <button
            :disabled="!state.activeSaveBtn"
            class="confirm-btn sub-title-s1 text-gray-reverse"
            @click="actions.saveAddress()"
          >
            등록
          </button>
        </div>
      </div>

      <template v-if="state.showDaumAddressForm">
        <div class="map-component">
          <vue-daum-postcode @complete="actions.onComplete">
            <template #loading>
              <div>...Loading...</div>
            </template>
          </vue-daum-postcode>
        </div>
      </template>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../../../../components/common/BaseModal";
import { computed, reactive } from "vue";
import { VueDaumPostcode } from "vue-daum-postcode";
// import InputBasic from "../../../../components/console/inputs/InputBasic";
import SearchIcon from "../../../../components/console/icons/SearchIcon";
import InputAddress from "@/components/console/inputs/InputAddress.vue";
import InputBasicNew from "@/components/console/inputs/InputBasicNew.vue";

export default {
  name: "AddressModal",
  components: {
    InputBasicNew,
    InputAddress,
    SearchIcon,
    // InputBasic,
    BaseModal,
    VueDaumPostcode,
  },
  emits: ["setAddress"],
  setup(props, { emit }) {
    const state = reactive({
      showDaumAddressForm: false,
      resAddress: "",
      detailAddress: "",
      address: computed(() => {
        return `${state.resAddress} ${state.detailAddress}`;
      }),
      isSetUpdateInputAddress: false,
      activeSaveBtn: computed(() => {
        return !!state.resAddress;
      }),
    });

    const actions = {
      onComplete: (data) => {
        console.log(data);
        state.resAddress = data.address + " " + data.buildingName;
        state.showDaumAddressForm = false;
      },
      closeModal: () => {
        emit("hideModal");
      },
      updateDetailAddress: (value) => {
        state.detailAddress = value;
      },
      openDaumAddressForm: () => {
        state.showDaumAddressForm = true;
      },
      saveAddress: () => {
        console.log(state.address);
        emit("setAddress", state.address);
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
