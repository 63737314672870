<template>
  <div>
    <label v-if="label" class="formatted-input-label">
      <span class="sub-text-s2">{{ label }}</span>
    </label>
    <input
      class="formatted-input b-text-1"
      :class="{ error: errorMessage }"
      :type="type"
      :placeholder="placeholder"
      :value="state.inputValue ? formatFunction(state.inputValue) : ''"
      @input="actions.setInputMoney($event)"
      @focusout="actions.focusoutEvent($event)"
    />
    <div v-if="errorMessage">
      <span class="sub-text-s3 text-red-50">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch } from "vue";

export default {
  name: "InputFormat",
  props: {
    type: {
      type: String,
      default: "text",
    },
    formatFunction: {
      type: Function,
      required: true,
    },
    value: {
      type: [Number, String],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    isFocusOutEvent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateData", "focusoutEvent"],
  setup(props, { emit }) {
    const number = ref();
    const state = reactive({
      inputValue: props.value ? props.value : "",
    });

    watch(
      () => props.value,
      (newVal) => {
        state.inputValue = newVal;
      }
    );

    const actions = {
      setInputMoney: (e) => {
        // 숫자 외에 입력값 방지, format 특성상 type 을 number 로 해서 해결할 수 없음.
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
        state.inputValue = e.target.value.replace(/[^0-9]/g, "");
        emit("updateData", state.inputValue);
      },
      focusoutEvent: (e) => {
        if (!props.isFocusOutEvent) {
          return;
        }
        emit("focusoutEvent", e.target.value);
      },
    };

    return { state, actions, number };
  },
};
</script>

<style scoped>
.formatted-input-label {
  display: block;
  margin-bottom: 8px;
}
.formatted-input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e6eaef;
  border-radius: 4px;
  padding: 14px 16px;
}

.formatted-input.error {
  border: 1px solid #ff4646;
}

.formatted-input::placeholder {
  color: #d0d5de;
}
</style>
